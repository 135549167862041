import { fetchUserInfo } from "@/api/user";
import { createAuenBid } from "@/api/bids";
import { fetchSales } from "@/api/sale";

export const actions = {
  close() {
    parent.postMessage(
      {
        action: "close",
      },
      "*"
    );
  },

  async fetchUserInfo({ commit }) {
    try {
      const { data } = await fetchUserInfo();
      commit("setUserInfo", data.getUser);
    } catch (error) {
      commit("errorNotification", {
        error,
      });
    }
  },

  async fetchSale({ commit, state }) {
    try {
      const { data } = await fetchSales({ ids: [state.saleId] });
      if (!data.getSales && !data.getSales.length) {
        commit("errorNotification", {
          text: "Sale not found",
        });
      } else {
        commit("setSale", data.getSales[0]);
      }
    } catch (error) {
      commit("errorNotification", {
        error,
      });
    }
  },

  async createAuenBid({ commit }, payload) {
    try {
      commit("showOverlay");
      await createAuenBid(payload);
    } catch (error) {
      commit("errorNotification", {
        error,
      });
      return Promise.reject();
    } finally {
      commit("hideOverlay");
    }
  },
};
