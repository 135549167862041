import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/style/global.scss";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from "@/store";

import { Utils } from "@/utils";
import { Amplify } from "aws-amplify";
import { awsConfig } from "./aws_config";

import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
});

Amplify.configure(awsConfig);

const app = createApp(App);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.config.globalProperties.$buckets = awsConfig.Storage.AWSS3.buckets;

app.directive("currency", {
  mounted: (value: number) => {
    if (!value || typeof value !== "number") return "";
    return Utils.formatPrice(value);
  },
});

app.use(vuetify).use(store).use(router).mount("#app");
