import { API, GraphQLResult } from "@aws-amplify/api";
import type { GetSalesQuery, GetSalesQueryVariables } from "@/API";

export const fetchSales = (variables: GetSalesQueryVariables) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[1].endpoint;

  const query = `query getSales($ids: [String!]!){
            getSales(ids: $ids) {
                id
                kind
                status
                englishAuction {
                    startingPrice
                    bidIncrement
                    startDate
                    endDate
                    currentBidValue
                    currentBidOwner
                }
            }
        }`;

  return API.graphql<GraphQLResult<GetSalesQuery>>({
    query,
    variables,
  });
};
