export const awsConfig = {
  Auth: {
    identityPoolId: "us-east-1:4e34a9d2-d334-4e9a-ac08-397a68067e20",
    region: "us-east-1",
    userPoolId: "us-east-1_o1LokNLOj",
    userPoolWebClientId: "74q45s1onenhcalvanj3vbid2g",
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    aws_appsync_graphqlEndpoint:
      "https://zhuvcxmeurflnbzzambb3otbim.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "asset",
        endpoint:
          "https://zhuvcxmeurflnbzzambb3otbim.appsync-api.us-east-1.amazonaws.com/graphql",
      },
      {
        name: "auctions",
        endpoint:
          "https://qm6komm2affutn6z7goig2ccw4.appsync-api.us-east-1.amazonaws.com/graphql",
      },
      {
        name: "asset v2",
        endpoint:
          "https://4dco6esorrai3gztgciogvcq7m.appsync-api.us-east-1.amazonaws.com/graphql",
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: "dev-auen-assets-static",
      region: "us-east-1",
    },
  },
};
