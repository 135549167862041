import { API, GraphQLResult } from "@aws-amplify/api";
import { CreateAuenBidMutationVariables, CreateAuenBidMutation } from "@/API";
export const createAuenBid = (variables: CreateAuenBidMutationVariables) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[1].endpoint;
  const query = /* GraphQL */ `
    mutation createAuenBid($bid: AuenBidInput!) {
      createAuenBid(bid: $bid) {
        id
        sale {
          id
          createdAt
          assetId
          agentId
          status
          kind
          englishAuction {
            startingPrice
            bidIncrement
            startDate
            endDate
            backups
            currentBidValue
            currentBidDate
            currentBidID
            currentBidOwner
            pendingParticipations
            approvedParticipations
            details
          }
        }
        bid {
          id
          assetId
          saleId
          owner
          agentId
          contentAgentId
          status
          kind
          name
          bidValue
          createdAt
          personalInfo {
            firstName
            lastName
            email
            phoneNumber
            avatar
          }
        }
        status
        name
        agent {
          firstName
          lastName
          companyName
          id
          phoneNumber
        }
        owner
        personalInfo {
          firstName
          lastName
          email
          phoneNumber
          avatar
        }
        saleId
        assetId
        agentId
      }
    }
  `;

  return API.graphql<GraphQLResult<CreateAuenBidMutation>>({
    query,
    variables,
  });
};
