export const getters = {
  agentFullName: (state) => {
    console.log(state.agent);
    const { firstName = "", lastName = "" } = state.agent ?? {};

    if (firstName || lastName) {
      return ((firstName || "") + " " + (lastName || "")).trim();
    }

    return null;
  },

  saleLoaded: (state) => {
    return state.sale.id;
  },
};
