export const EVENT_BUS_EVENTS = {
  NEXT_STEP: "NEXT_STEP",
  PREV_STEP: "PREV_STEP",
};

import mitt, { Handler } from "mitt";
export default new (class EventBus {
  emitter;
  events: string[] = [];

  constructor() {
    this.emitter = mitt();
  }

  $on(event: string, callback: Handler<unknown>) {
    this.events.push(event);
    return this.emitter.on(event, callback);
  }

  $off(event: string, callback: Handler<unknown>) {
    this.events.splice(this.events.indexOf(event), 1);
    return this.emitter.off(event, callback);
  }

  $emit(event: string) {
    if (this.events.includes(event)) {
      return this.emitter.emit(event);
    } else {
      throw new Error("Event Bus: Event doesn't exist");
    }
  }

  $clear() {
    this.events = [];
    return this.emitter.all.clear();
  }
})();
