import { API } from "@aws-amplify/api";

export const fetchUserInfo = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API._graphqlApi._options.aws_appsync_graphqlEndpoint =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._options.API.endpoints[0].endpoint;

  const query = `query {
            getUser: getUser {
                email
                personalInfo {
                    firstName
                    lastName
                    phoneNumber
                    avatar
                }
            }
        }`;

  return API.graphql({
    query,
  });
};
