import { generateFileKey } from "@/utils/generateFileKey";

export const Utils = {
  /*
   * Example key
   * protected/user-identity-id/image.jpg
   */
  parseFileKey(key: string) {
    return {
      key: key.split("/")[2],
      identityId: key.split("/")[1],
      level: key.split("/")[0],
    };
  },

  /*
   * Example
   * fileType = image/png
   */
  generateFileKey,

  formatPrice(price: number) {
    if (!price) {
      return price;
    } else {
      return `$${new Intl.NumberFormat("en-US").format(price)}`;
    }
  },

  getFileIcon(type: string) {
    if (type.includes("pdf")) {
      return "mdi-file-pdf";
    } else if (
      ["image", "jpeg", "jpg", "png", "webp"].some(
        (substr) => type.indexOf(substr) >= 0
      )
    ) {
      return "mdi-file-image-outline";
    } else {
      return "mdi-file";
    }
  },

  formatPhoneNumber(value: string) {
    const newValueArr = value.replace(/[^\d]/g, "").split("");

    const firstPart = newValueArr.slice(0, 3).join("");
    const secondPart = newValueArr.slice(3, 6).join("");
    const thirdPart = newValueArr.slice(6, 10).join("");

    let newValue = "";
    newValue += firstPart ? `(${firstPart})` : "";
    newValue += secondPart ? ` ${secondPart}` : "";
    newValue += thirdPart ? `-${thirdPart}` : "";

    return newValue;
  },
};
