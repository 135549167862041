export const mutations = {
  setAuctionInfo(state, { agent, saleId }) {
    state.agent = agent;
    state.saleId = saleId;
  },

  setSale(state, sale) {
    state.sale = sale;
  },

  updateEnglishAuction(state, payload) {
    state.sale[state.sale.kind] = {
      ...state.sale[state.sale.kind],
      ...payload,
    };
  },

  setCurrentBidValue(state, currentBidValue) {
    state.sale.englishAuction.currentBidValue = currentBidValue;
  },

  setCurrentBidOwner(state, currentBidOwner) {
    state.sale.englishAuction.currentBidOwner = currentBidOwner;
  },

  setUserInfo(state, payload) {
    state.userInfo = {
      ...payload,
    };
  },

  errorNotification(state, { error, text, subtext, closable }) {
    error && console.error(error);

    state.snackbar = {
      text: text
        ? text
        : (error.errors && error.errors[0].message) || "Something went wrong",
      subtext: subtext || "",
      closable: !!closable,
      color: "error",
      isOpened: true,
    };
  },

  showOverlay(state) {
    state.loading = true;
  },

  hideOverlay(state) {
    state.loading = false;
  },

  closeSnackbar(state) {
    state.snackbar = {
      text: "",
      subtext: "",
      isOpened: false,
      color: "success",
      closable: false,
    };
  },

  successNotification(state, { text, subtext, closable }) {
    state.snackbar = {
      text,
      subtext: subtext || "",
      closable: !!closable,
      color: "success",
      isOpened: true,
    };
  },
};
