import { ref, onMounted, onUnmounted, unref, Ref } from "vue";
import moment from "moment/moment";
import { EnglishAuction } from "@/API";
import { ICountdown } from "@/types/bids";

export default function useCountdown(auction: Ref<EnglishAuction>) {
  const countdown: Ref<ICountdown> = ref({
    interval: null,
    value: null,
  });

  const calculateCountdown = (): string => {
    const currentTime = moment().unix();
    const endTime = moment(unref(auction).endDate).unix();

    if (endTime - currentTime < 0) {
      return "00:00:00";
    }

    const duration = moment.duration(
      (endTime - currentTime) * 1000,
      "milliseconds"
    );

    const days = Math.floor(duration.asMilliseconds() / 1000 / 60 / 60 / 24);
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");

    return `${days ? days + "d " : ""}${hours}:${minutes}:${seconds}`;
  };

  onMounted(() => {
    countdown.value.value = calculateCountdown();
    countdown.value.interval = setInterval(() => {
      countdown.value.value = calculateCountdown();
    }, 1000);
  });

  onUnmounted(() => {
    if (!countdown.value.interval) return;
    clearInterval(countdown.value.interval);
  });
  return {
    countdown,
    calculateCountdown,
  };
}
