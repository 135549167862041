import { API } from "@aws-amplify/api";
import type { GraphQLSubscription } from "@aws-amplify/api";
import type {
  OnEditAuctionSubscription,
  OnEditAuctionSubscriptionVariables,
  OnSetCurrentBidSubscription,
  OnSetCurrentBidSubscriptionVariables,
} from "@/API";

export default {
  onSetCurrentBid(variables: OnSetCurrentBidSubscriptionVariables) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._graphqlApi._options.aws_appsync_graphqlEndpoint =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      API._options.API.endpoints[1].endpoint;

    const query = /* GraphQL */ `
      subscription onSetCurrentBid($id: String!) {
        onSetCurrentBid(id: $id) {
          id
          englishAuction {
            currentBidValue
            currentBidOwner
          }
        }
      }
    `;
    return API.graphql<GraphQLSubscription<OnSetCurrentBidSubscription>>({
      query,
      variables,
    });
  },

  onEditAuction: function (variables: OnEditAuctionSubscriptionVariables) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    API._graphqlApi._options.aws_appsync_graphqlEndpoint =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      API._options.API.endpoints[1].endpoint;

    const query =
      /* GraphQL */
      `
        subscription onEditAuction($id: String!) {
          onEditAuction(id: $id) {
            id
            englishAuction {
              endDate
              bidIncrement
              startingPrice
            }
          }
        }
      `;

    const result = API.graphql<GraphQLSubscription<OnEditAuctionSubscription>>({
      query,
      variables,
    });

    return result.map(({ value }) => {
      return value?.data?.onEditAuction?.englishAuction;
    });
  },
};
