import { Utils } from "@/utils/index";

export const validators = {
  required(v: string, fieldName = "Field") {
    return !!v || fieldName + " is required";
  },

  moreOrEquals(v: number, min: number): string | true {
    return (
      v >= min ||
      "Enter a value greater than or equal to " + Utils.formatPrice(min)
    );
  },
};
